import React, { PureComponent } from "react";
import './PallotoneDesktop.scss';
import pallotoneTrackPage from './images/pallotone-track-page.jpg';

export default class PtoneDesktop extends PureComponent {
  render() {
    return (
      <div className="hero-figures">
        <div className="speaker left-speaker">
          <div className="mini-circle"></div>
          <div className="circle top-circle"></div>
          <div className="circle bottom-circle"></div>
        </div>
        <div className="imac">
          <div className="screen">
            <div className="screen-inner">
              <img src={pallotoneTrackPage} className="screenshot"
                alt="Pallotone track page" />
              <div className="screen-inner-mask"></div>
            </div>
            <div className="screen-chin"></div>
          </div>
          <div className="stand-neck"></div>
          <div className="stand-base"></div>
        </div>
        <div className="speaker right-speaker">
          <div className="mini-circle"></div>
          <div className="circle top-circle"></div>
          <div className="circle bottom-circle"></div>
        </div>
      </div>

    );
  }
}