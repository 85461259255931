import React, { PureComponent } from 'react';
import './CompoundDesktop.scss';
import compoundDashboard from './images/compound-dashboard-page.jpg';

export default class CompoundDesktop extends PureComponent {
  render() {
    return (
      <div className="browser">
        <div className='browser-window'>
          <div className='browser-window-inner'>
            <img
              src={compoundDashboard}
              className='screenshot'
              alt='Compound dashboard page'
            />
            <div className='browser-window-inner-mask'></div>
          </div>
        </div>
      </div>
    );
  }
}
