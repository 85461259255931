import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './ContactForm.scss';
function FormspreeForm() {
  const [state, handleSubmit] = useForm("mwkypqwq");
  if (state.succeeded) {
    return <div className="confirmation-card"><span className="material-icons done-icon">check_circle</span>Message sent succesfully!</div>;
  }
  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email">
        Email Address
      </label>
      <input
        id="email"
        type="email"
        name="email"
      />
      <ValidationError
        className="validation-error"
        prefix="Email"
        field="email"
        errors={state.errors}
      />
      <label htmlFor="message">
        Message
      </label>
      <textarea
        id="message"
        name="message"
        rows="5"
      />
      <ValidationError
        className="validation-error"
        prefix="Message"
        field="message"
        errors={state.errors}
      />
      <button className="submit-button" type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
}
function ContactForm() {
  return (
    <FormspreeForm />
  );
}
export default ContactForm;